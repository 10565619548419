import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import gsap from "gsap";
import { Howl } from "howler";
import jacksrbetterJson from "../jrbjson.json";
import resultCards from "../resultCards";

import reaveldealer_cards_sound from "../../../../../../assets/sounds/dealercards_reavel.mp3";
// import emtyCard from "../../../../../../assets/games/jacksorbetter/Asset 1.svg";
import emtyCard from "../../../../../../assets/games/jacksorbetter/emtyCard.svg";

import "./index.css"




const CardsContainer = forwardRef((props, ref) => {

    const [classVaribles, setclassVaribles] = useState({});
    const [flipSound, setFlipSound] = useState({ betSound: null, isPlayingFlipSound: null });

    const reaveldealer_cards = useRef(null);


    let initializeCardFlipSound = () => {
        try {
            let betSound = new Howl({ src: [reaveldealer_cards_sound], html5: true, });
            setFlipSound((prev) =>({ ...prev, betSound, isPlayingFlipSound: false}));
            return betSound;
        } catch (error) {
            console.error("Error initializing bet sound:", error);
            return null;
        }
    };

   
    useEffect(() => {
        reaveldealer_cards.current = initializeCardFlipSound();
        // console.log(flipSound.betSound)

        return () =>{
            reaveldealer_cards.current.pause();
        }
    }, [flipSound.isPlayingFlipSound])

    const { cardDetails, clickOncardToHoldorUnhold, isSoundOn } = props;
    // console.log(cardDetails);
    const { id, src, value, hold } = cardDetails

    useEffect(() => {
        const isActiveWindowsMode =
            (navigator.userAgentData && navigator.userAgentData.platform && navigator.userAgentData.platform === "Windows") ||
            navigator.userAgent.indexOf("Windows") !== -1;

        // Determine if the device is in portrait or landscape mode
        const isActivePortraitMode = window.innerHeight > window.innerWidth;
        const isActiveLandscapeMode = window.innerWidth > window.innerHeight;
        if (isActiveWindowsMode) {
            setclassVaribles({
                liContainer: jacksrbetterJson.liContainer,
                cardImage: jacksrbetterJson.cardImage,
                holdSpan: jacksrbetterJson.holdSpan

            })

        } else if (isActivePortraitMode) {
            setclassVaribles({
                liContainer: jacksrbetterJson.liContainerPrt,
                cardImage: jacksrbetterJson.cardImage,
                holdSpan: jacksrbetterJson.holdSpan

            })

        } else {
            setclassVaribles({
                liContainer: jacksrbetterJson.liContainerPrt,
                cardImage: jacksrbetterJson.cardImage,
                holdSpan: jacksrbetterJson.holdSpan

            })

        }



    }, []);




    let playRemainingSounds = (chipSound) => {
        let currentSound = chipSound;
        if (isSoundOn) {
            // console.log(chipSound)

            if (chipSound && flipSound.isPlayingFlipSound) {
                // chipSound.stop();
            }
            if (!chipSound) {
                currentSound = initializeCardFlipSound();
            }

            currentSound.play();

            setFlipSound({
                currentSound,
                isPlayingFlipSound: true,
            });

        }

    };
    useImperativeHandle(ref, () => ({

        dealBetResultRes(res) {
            console.log(res);
            let cardLength = res.cards.length;
            console.log("cardLength", cardLength)
            for (let i = 0; i < cardLength; i++) {


                let revelFaceImage = src;
                console.log("resultCards.length", resultCards, resultCards.length);
                for (let j = 0; j < resultCards.length; j++) {
                    if (resultCards[j].rsc_val === res.cards[i]) {
                        revelFaceImage = resultCards[j].src;
                        // console.log("revelFaceImage :", revelFaceImage)


                        let t1 = gsap.timeline();
                        // gsap.fromTo(`#jrb${i}`,
                        //     {rotateY:90, ease: "EaseInOut",duration:0.1,opacity:1,delay:i*0.15+0.1, 
                        //         onComplete:()=>{
                        //         const cardElement = document.getElementById(`jrb${i}`);
                        //         cardElement.src = revelFaceImage},
                        //     },
                        //     {rotateY:0, ease: "EaseInOut",duration:0.2,opacity:1,delay:i*0.15},

                        // )

                        t1.to(`#jrb${i}`, { rotateY: 90, ease: "EaseInOut", duration: 0.3, autoAlpha: 1, delay: i * 0.05 + 0.3, });
                        t1.to(`#jrb${i}`, {
                            rotateY: 0, ease: "EaseInOut", duration: 0.3, autoAlpha: 1, delay: i * 0.05 + 0.1, onStart: () => {
                                playRemainingSounds(flipSound.betSound)
                                const cardElement = document.getElementById(`jrb${i}`);
                                // cardElement.src = src;
                                cardElement.src = revelFaceImage;
                            },
                            onComplete: () => {
                                if (res.autoHoldCards) {
                                    if (res.autoHoldCards[i]) {
                                        console.log(res.autoHoldCards[i], i);
                                        clickOncardToHoldorUnhold(i);
                                    }
                                }
                            }
                        })



                    }
                }

            }

        },

        drawUnselectedCards(drawResponse, dealerResponse) {

            let dealerCards = dealerResponse.cards;
            let drawCards = drawResponse.cards;

            console.log("dealResponse ", dealerResponse, "drawResponse ", drawResponse);

            let cardLength = dealerResponse.cards.length;
            if (dealerResponse && drawResponse) {


                for (let i = 0; i < cardLength; i++) {
                    if (dealerCards[i] !== drawCards[i]) {
                        const cardElement = document.getElementById(`jrb${i}`);
                        for (let j = 0; j < resultCards.length; j++) {
                            if (resultCards[j].rsc_val === drawCards[i]) {
                                let t1 = gsap.timeline();
                                t1.to(`#jrb${i}`, {
                                    rotateY: 90, ease: "EaseInOut", duration: 0.3, autoAlpha: 1, delay: i * 0.05 + 0.3, onStart: () => {
                                        playRemainingSounds(flipSound.betSound)
                                        cardElement.src = emtyCard;
                                    }, onComplete: () => { cardElement.src = resultCards[j].src; }
                                });

                                t1.to(`#jrb${i}`, {
                                    rotateY: 0, ease: "EaseInOut", duration: 0.3, autoAlpha: 1, delay: i * 0.05 + 0.1, onStart: () => {

                                    }
                                })
                            }

                        }
                    }

                }

            }

        },



        restartTheGame(intialCards) {

            let cardLength = intialCards.length;
            console.log("cardLength", cardLength)
            for (let i = 0; i < cardLength; i++) {
                let t2 = gsap.timeline();
                t2.to(`#jrb${i}`, { rotateY: -90, ease: "EaseInOut", duration: 0.01, autoAlpha: 1, });
                t2.to(`#jrb${i}`, {
                    rotateY: 0, ease: "EaseInOut", duration: 0.3, autoAlpha: 1, delay: i * 0.05 + 0.1, onStart: () => {
                        playRemainingSounds(flipSound.betSound);
                        const cardElement = document.getElementById(`jrb${i}`);
                        cardElement.src = intialCards[i].src;
                    },

                })

            }

        },



    }))



    const clickOncardToHoldorUnholdcard = () => {
        clickOncardToHoldorUnhold(id)
    }





    return (
        <li className="jacksCardContainer" style={classVaribles.liContainer}>
            <button onClick={clickOncardToHoldorUnholdcard}>
                <img id={`jrb${id}`} style={classVaribles.cardImage} src={src} />
            </button>
            {hold && <span style={classVaribles.holdSpan}>Hold</span>}
        </li>
    )

})


export default CardsContainer