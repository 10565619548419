import react from "react";
import R from "../../../../../assets/games/russianPoker/russiantitle/russian poker-01.png";
import u from "../../../../../assets/games/russianPoker/russiantitle/russian poker-02.png";
import s from "../../../../../assets/games/russianPoker/russiantitle/russian poker-03.png";
import S from "../../../../../assets/games/russianPoker/russiantitle/russian poker-04.png";
import i from "../../../../../assets/games/russianPoker/russiantitle/russian poker-05.png";
import a from "../../../../../assets/games/russianPoker/russiantitle/russian poker-06.png";
import n from "../../../../../assets/games/russianPoker/russiantitle/russian poker-07.png";
import p from "../../../../../assets/games/russianPoker/russiantitle/russian poker-08.png";
import o from "../../../../../assets/games/russianPoker/russiantitle/russian poker-09.png";
import k from "../../../../../assets/games/russianPoker/russiantitle/russian poker-10.png";
import e from "../../../../../assets/games/russianPoker/russiantitle/russian poker-11.png";
import r from "../../../../../assets/games/russianPoker/russiantitle/russian poker-12.png";


const Tittle = [
    { src: R,id:1},
    { src: u,id:2 },
    { src: s,id:3 },
    { src: S,id:4},
    { src: i,id:5 },
    { src: a, id:6},
    { src: n, id:7},
    { src: p, id:8},
    { src: o, id:9},
    { src: k, id:10},
    { src: e, id:11},
    { src: r, id:12},
   
  ];

  export default Tittle;