import react from "react";
import card from "../../../../../assets/games/funCard/resCards/2c.svg"
import da from '../../../../../assets/games/funCard/resCards/ad.svg';
import ca from '../../../../../assets/games/funCard/resCards/ac.svg';
import ha from '../../../../../assets/games/funCard/resCards/ah.svg';
import sa from '../../../../../assets/games/funCard/resCards/as.svg';
import d2 from '../../../../../assets/games/funCard/resCards/2d.svg';
import c2 from '../../../../../assets/games/funCard/resCards/2c.svg';
import h2 from '../../../../../assets/games/funCard/resCards/2h.svg';
import s2 from '../../../../../assets/games/funCard/resCards/2s.svg';
import d3 from '../../../../../assets/games/funCard/resCards/3d.svg';
import c3 from '../../../../../assets/games/funCard/resCards/3c.svg';
import h3 from '../../../../../assets/games/funCard/resCards/3h.svg';
import s3 from '../../../../../assets/games/funCard/resCards/3s.svg';
import d4 from '../../../../../assets/games/funCard/resCards/4d.svg';
import c4 from '../../../../../assets/games/funCard/resCards/4c.svg';
import h4 from '../../../../../assets/games/funCard/resCards/4h.svg';
import s4 from '../../../../../assets/games/funCard/resCards/4s.svg';
import d5 from '../../../../../assets/games/funCard/resCards/5d.svg';
import c5 from '../../../../../assets/games/funCard/resCards/5c.svg';
import h5 from '../../../../../assets/games/funCard/resCards/5h.svg';
import s5 from '../../../../../assets/games/funCard/resCards/5s.svg';
import d6 from '../../../../../assets/games/funCard/resCards/6d.svg';
import c6 from '../../../../../assets/games/funCard/resCards/6c.svg';
import h6 from '../../../../../assets/games/funCard/resCards/6h.svg';
import s6 from '../../../../../assets/games/funCard/resCards/6s.svg';
import d7 from '../../../../../assets/games/funCard/resCards/7d.svg';
import c7 from '../../../../../assets/games/funCard/resCards/7c.svg';
import h7 from '../../../../../assets/games/funCard/resCards/7h.svg';
import s7 from '../../../../../assets/games/funCard/resCards/7s.svg';
import d8 from '../../../../../assets/games/funCard/resCards/8d.svg';
import c8 from '../../../../../assets/games/funCard/resCards/8c.svg';
import h8 from '../../../../../assets/games/funCard/resCards/8h.svg';
import s8 from '../../../../../assets/games/funCard/resCards/8s.svg';
import d9 from '../../../../../assets/games/funCard/resCards/9d.svg';
import c9 from '../../../../../assets/games/funCard/resCards/9c.svg';
import h9 from '../../../../../assets/games/funCard/resCards/9h.svg';
import s9 from '../../../../../assets/games/funCard/resCards/9s.svg';
import dt from '../../../../../assets/games/funCard/resCards/td.svg';
import ct from '../../../../../assets/games/funCard/resCards/tc.svg';
import ht from '../../../../../assets/games/funCard/resCards/th.svg';
import st from '../../../../../assets/games/funCard/resCards/ts.svg';
import dj from '../../../../../assets/games/funCard/resCards/jd.svg';
import cj from '../../../../../assets/games/funCard/resCards/jc.svg';
import hj from '../../../../../assets/games/funCard/resCards/jh.svg';
import sj from '../../../../../assets/games/funCard/resCards/js.svg';
import dq from '../../../../../assets/games/funCard/resCards/qd.svg';
import cq from '../../../../../assets/games/funCard/resCards/qc.svg';
import hq from '../../../../../assets/games/funCard/resCards/qh.svg';
import sq from '../../../../../assets/games/funCard/resCards/qs.svg';
import dk from '../../../../../assets/games/funCard/resCards/kd.svg';
import ck from '../../../../../assets/games/funCard/resCards/kc.svg';
import hk from '../../../../../assets/games/funCard/resCards/kh.svg';
import sk from '../../../../../assets/games/funCard/resCards/ks.svg';

const resultCards = [
    {src: da, rsc_val : 'AD' },
    {src: ca, rsc_val : 'AC' },
    {src: ha, rsc_val : 'AH' },
    {src: sa, rsc_val : 'AS' },
    {src: d2, rsc_val : '2D' },
    {src: c2, rsc_val : '2C' },
    {src: h2, rsc_val : '2H' },
    {src: s2, rsc_val : '2S' },
    {src: d3, rsc_val : '3D' },
    {src: c3, rsc_val : '3C' },
    {src: h3, rsc_val : '3H' },
    {src: s3, rsc_val : '3S' },
    {src: d4, rsc_val : '4D' },
    {src: c4, rsc_val : '4C' },
    {src: h4, rsc_val : '4H' },
    {src: s4, rsc_val : '4S' },
    {src: d5, rsc_val : '5D' },
    {src: c5, rsc_val : '5C' },
    {src: h5, rsc_val : '5H' },
    {src: s5, rsc_val : '5S' },
    {src: d6, rsc_val : '6D' },
    {src: c6, rsc_val : '6C' },
    {src: h6, rsc_val : '6H' },
    {src: s6, rsc_val : '6S' },
    {src: d7, rsc_val : '7D' },
    {src: c7, rsc_val : '7C' },
    {src: h7, rsc_val : '7H' },
    {src: s7, rsc_val : '7S' },
    {src: d8, rsc_val : '8D' },
    {src: c8, rsc_val : '8C' },
    {src: h8, rsc_val : '8H' },
    {src: s8, rsc_val : '8S' },
    {src: d9, rsc_val : '9D' },
    {src: c9, rsc_val : '9C' },
    {src: h9, rsc_val : '9H' },
    {src: s9, rsc_val : '9S' },
    {src: dt, rsc_val : '10D' },
    {src: ct, rsc_val : '10C' },
    {src: ht, rsc_val : '10H' },
    {src: st, rsc_val : '10S' },
    {src: dj, rsc_val : 'JD' },
    {src: cj, rsc_val : 'JC' },
    {src: hj, rsc_val : 'JH' },
    {src: sj, rsc_val : 'JS' },
    {src: dq, rsc_val : 'QD' },
    {src: cq, rsc_val : 'QC' },
    {src: hq, rsc_val : 'QH' },
    {src: sq, rsc_val : 'QS' },
    {src: dk, rsc_val : 'KD' },
    {src: ck, rsc_val : 'KC' },
    {src: hk, rsc_val : 'KH' },
    {src: sk, rsc_val : 'KS' },
     
]

export default resultCards;